.flex {
    display: flex;
}

.row {
    flex-direction: row;
}

.column {
    flex-direction: column;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.align-center {
    align-items: center;
}

.app, a {
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #f5f5f5;
    color:  #4B4B4B;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.container_center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container_center_horizontal {
    display: flex;
    justify-content: center;
}

