.img {
    height: 316px;
    width: 316px;
    margin: 20px;
    object-fit: cover;
}

.text_copyright_centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container_img {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
}

.exhibition_img {
    height: 455px;
    width: 360px;
    margin: 20px;
    object-fit: cover;
}