.container_social_links {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    gap: 10px
}

.container_social_link {
    display: flex;
}

.svg_social-link {
    margin-right: 10px;
}