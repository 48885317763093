.container_about{
    display: flex;
}

.text_lang {
    padding-top: 10px;
    margin-right: 90px;

}

.text_paragraph_about {
    margin: 0px;
    text-align: justify;
}