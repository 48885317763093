.vh-100 {
    height: 100vh;
}

.img_preview_container {
    height: 85%;
    padding-top: 30px;
    margin-bottom: 20px;
}

.img_preview {
    height: 85%;
}

.img_description {
    text-align: left;
}