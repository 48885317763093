.sidebar {
    height: 100vh;
    text-align: left;
    padding: 0 8px;
    min-width: 400px;
    font-weight: bold;

}

.sidebar_content_sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.sidebar_img {
    height: 306px;
    width: 169px;
    object-fit: cover;
}